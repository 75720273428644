@tailwind base;
@tailwind components;
@tailwind utilities;

/* .logo-image {
    filter: brightness(1) invert(0);
} */

@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Nunito:wght@800&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Nunito:wght@800&family=Overpass&display=swap');
